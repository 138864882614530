<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro14.png" alt="" />
    <div class="yj">
      <h4><span></span>文教创新</h4>
      <p>定位-搭建校企优质人才教培桥梁，满足行业多元化用人需求。 </p>
      <p>集团以职业教育作为文教创新业务支点，推动大健康行业的人才创新、体系传承与可持续发展。集团整合优质行业企业资源与师资资源， 完善教培机制，加强校企合作和专业共建，积极调动企业参与程度，打造联合招生，联合培养，联合用工的新型培养模式。</p>
    </div>
  </div>
  <div class="main" v-else>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.38rem;
        border-top: 0.3rem solid #e5e5e5;
        border-bottom: 0.4rem solid #e5e5e5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.1rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        p{
            width: 6.8rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
        }
        :nth-child(2){
            margin-bottom: 0.2rem;
        }
      }
      
    }
  }
  @media screen and (min-width: 960px) { 
    
  }
</style>